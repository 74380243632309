.ant-layout-sider-zero-width-trigger {
	top: 12px;
	z-index: 1001;
}

.SideNav__spacer {
	text-align: center;
	height: 64px;
}

.SideNav__logo {
	height: 100%;
}
