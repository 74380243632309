body {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

#root {
	height: 100%;
}

.ant-layout-sider-zero-width-trigger {
	top: 12px;
	z-index: 1001;
}

.SideNav__spacer {
	text-align: center;
	height: 64px;
}

.SideNav__logo {
	height: 100%;
}

.Home__image {
	width: 100%;
}

.Home__cards {
	display: flex;
	flex-direction: column;
	padding: 30px;
}

.Home__card {
	text-align: center;
	margin-bottom: 10px;
}

.Home__cardIcon {
	font-size: 65px;
	margin-bottom: 10px;
}

@media (min-width: 700px) {
	.Home__cards {
		flex-direction: row;
		justify-content: space-between;
	}

	.Home__card {
		width: 30%;
	}
}

.About {
	align-items: center;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	padding-top: 30px;
	max-width: 1100px;
	margin: 0 auto;
}

.About__card {
	width: 95%;
	max-width: 500px;
}

.About__img {
	width: 100%;
	max-width: 420px;
}

@media (min-width: 700px) {
	.About {
		flex-direction: row;
	}

	.About__card {
		width: 48%;
	}
}

.Episode {
	padding: 20px;
}

.AllEpisodes {
	padding: 20px;
}

.AllEpisodes__card {
	margin-bottom: 10px;
}

.MainContent {
	background-color: #fff;
	margin-top: 64px;
	min-height: unset;
}

.MainHeader {
	position: fixed;
	z-index: 1000;
	width: 100%;
}

.MainHeader__text {
	color: #fff;
	height: 64px;
	overflow: hidden;
}

.MainFooter {
	display: flex;
	flex-direction: column;
}

.MainFooter__column {
	width: 100%;
}

.MainFooter__rssIcon {
	margin-left: 3px;
	width: 14px;
}

.MainFooter__column--disclaimer {
	border-top: 1px solid #d4d4d4;
	border-bottom: 1px solid #d4d4d4;
	margin: 15px 0;
	padding: 15px 0;
}

.MainFooter__podcastImg {
	width: 100%;
}

@media (min-width: 700px) {
	.MainFooter {
		flex-direction: row;
		justify-content: space-between;
	}

	.MainFooter__column {
		width: unset;
	}

	.MainFooter__column--disclaimer {
		border-top: none;
		border-bottom: none;
		border-right: 1px solid #d4d4d4;
		border-left: 1px solid #d4d4d4;
		padding: 0 30px;
		margin: 0 15px;
		max-width: 500px;
	}

	.MainFooter__podcastImg {
		width: 220px;
	}

	.MainFooter__column--podcasts {
		text-align: right;
	}
}

.MainLayoutContainer {
	height: 100%;
}

