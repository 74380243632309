.About {
	align-items: center;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	padding-top: 30px;
	max-width: 1100px;
	margin: 0 auto;
}

.About__card {
	width: 95%;
	max-width: 500px;
}

.About__img {
	width: 100%;
	max-width: 420px;
}

@media (min-width: 700px) {
	.About {
		flex-direction: row;
	}

	.About__card {
		width: 48%;
	}
}
