.MainFooter {
	display: flex;
	flex-direction: column;
}

.MainFooter__column {
	width: 100%;
}

.MainFooter__rssIcon {
	margin-left: 3px;
	width: 14px;
}

.MainFooter__column--disclaimer {
	border-top: 1px solid #d4d4d4;
	border-bottom: 1px solid #d4d4d4;
	margin: 15px 0;
	padding: 15px 0;
}

.MainFooter__podcastImg {
	width: 100%;
}

@media (min-width: 700px) {
	.MainFooter {
		flex-direction: row;
		justify-content: space-between;
	}

	.MainFooter__column {
		width: unset;
	}

	.MainFooter__column--disclaimer {
		border-top: none;
		border-bottom: none;
		border-right: 1px solid #d4d4d4;
		border-left: 1px solid #d4d4d4;
		padding: 0 30px;
		margin: 0 15px;
		max-width: 500px;
	}

	.MainFooter__podcastImg {
		width: 220px;
	}

	.MainFooter__column--podcasts {
		text-align: right;
	}
}
