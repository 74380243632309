.Home__image {
	width: 100%;
}

.Home__cards {
	display: flex;
	flex-direction: column;
	padding: 30px;
}

.Home__card {
	text-align: center;
	margin-bottom: 10px;
}

.Home__cardIcon {
	font-size: 65px;
	margin-bottom: 10px;
}

@media (min-width: 700px) {
	.Home__cards {
		flex-direction: row;
		justify-content: space-between;
	}

	.Home__card {
		width: 30%;
	}
}
