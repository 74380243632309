.MainHeader {
	position: fixed;
	z-index: 1000;
	width: 100%;
}

.MainHeader__text {
	color: #fff;
	height: 64px;
	overflow: hidden;
}
